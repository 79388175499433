class Video {

  // Constructor
  constructor () {
    this.DOM = {
      video: document.querySelectorAll('.js-video')
    }
  }

  // Init
  init = () => {

    // Check if elements exist
    if (this.DOM.video.length > 0) {
      // If exist, loop
      this.DOM.video.forEach((video) => {

        // Get button
        let button = video.querySelector('.js-video-button');
        let url    = video.getAttribute('data-video');

        // Add event
        video.addEventListener('click', (e) => {

          // Prevent default
          e.preventDefault();

          // Create modal
          let modal = document.createElement('div');
          modal.classList.add('modal', 'modal--video', 'js-modal');

          // Set close button and iframe
          let inside = `
            <button class='modal__button modal__button--close js-close-modal'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.9 44.9"><path fill="#fff" d="M26.5 22.5L44.1 4.9c1.1-1.1 1.1-3 0-4.1s-3-1.1-4.1 0L22.5 18.4 4.9.8C3.8-.3 1.9-.3.8.8s-1.1 3 0 4.1l17.5 17.5L.8 40c-1.1 1.1-1.1 3 0 4.1s3 1.1 4.1 0l17.5-17.5L40 44.1c.6.6 1.3.8 2 .8s1.5-.3 2-.8c1.1-1.1 1.1-3 0-4.1L26.5 22.5z"/></svg>
            </button>
            <div class='modal__container'>
              <iframe class='modal__iframe' width='' height='' src='${url}' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>
            </div>
          `;

          // Insert html
          modal.insertAdjacentHTML('afterbegin', inside);

          // Create
          document.body.appendChild(modal);

          // Animate
          TweenMax.to(modal, 1, {
              top: 0,
              ease: Expo.easeInOut
          });

          // Set event listener for close
          let close = modal.querySelector('.js-close-modal');

          // When clicked, close the panel
          close.addEventListener('click', () => {

            // Animate
            TweenMax.to(modal, 1, {
              top: '100%',
              ease: Expo.easeInOut,
              onComplete: () => modal.remove()
            });
          });
        });
      });
    }
  }
}

// Export
export default Video;

// Call class
const video = new Video;
video.init();
