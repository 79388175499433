class Contact {

  // Constructor
  constructor() {
  }

  init = () => {

    // Log initiate
    // window.console.log('---');
    // window.console.log('Contact initialized');
  }
}

export default Contact;
