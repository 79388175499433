class Preload {

    constructor() {
        this.dom = {
            document: document
        }
    }

    init = () => {

        // Log
        // window.console.log('---');
        // window.console.log('Preload initialized');

        // Check if dom is home
        if (this.dom.document.body.classList.contains('home') === true) {

            // On ready
            this.dom.document.addEventListener('DOMContentLoaded', () => {
                // Animate
                TweenMax.to(this.dom.document.body, 1, {
                    opacity: 1,
                    ease: Ease.easeOut
                });
            });
        } else {}
    }
}

export default Preload;
