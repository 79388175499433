function initCookie() {
  const button = document.querySelector('.cookie input');
  if (!button) return;
  function setCookie() {
    let days = 30;
    let myDate = new Date();

    myDate.setTime(myDate.getTime() + (days * 24 * 60 * 60 * 1000));

    document.cookie = 'cookies=yes;';
    // document.cookie = 'cookies=yes; expires=' + myDate.toGMTString();
  }
  button.addEventListener('click', setCookie);
}
export default initCookie;
