import {
    Swiper,
    Pagination,
    Parallax,
} from 'swiper/dist/js/swiper.esm.js';
Swiper.use([Swiper, Pagination, Parallax]);

class Slider {

    constructor() {
        this.dom = {
            slider: document.querySelectorAll(".js-slider"),
            controls: document.querySelector(".js-controls"),
            swiper: document.querySelectorAll(".js-swiper")
        }
    }

    setNavigation = (slider, controls) => {
        let index = 1;
        // If large
        slider.forEach((instance) => {
            if (instance.classList.contains('slider--large')) {
                instance.childNodes.forEach((child) => {
                    if (child.classList !== undefined && child.classList.contains('js-slide') === true) {
                        let block = document.createElement('button');
                        if (index === 1) {
                            block.classList.add('js-active-slide', 'slider__controls-item');
                        } else {
                            block.classList.add('slider__controls-item');
                        }
                        controls.appendChild(block);
                        // window.console.log("- Button placed");
                        (index++);
                    }
                });
            }
        });
    }

    init = () => {
      window.addEventListener("load", () => {
        // check if slides exist
        if (this.dom.slider.length !== undefined) {
            // build navigation
            // this.setNavigation(this.dom.slider, this.dom.controls);

            // Log message
            // window.console.log("---");
            // window.console.log("Slider initialized");
        }

        // Initiate swipers
        if (this.dom.swiper.length !== undefined) {
          this.dom.swiper.forEach((slider) => {
            let pagination = slider.querySelector('.swiper-pagination');
            var slider = new Swiper(slider, {
              speed: 1500,
              parallax: true,
              loop: true,
              simulateTouch: false,
              pagination: {
                  el: pagination,
                  type: 'bullets',
                  spaceBetween: 0,
                  clickable: true,
                  bulletClass: 'slider__controls-item',
                  bulletActiveClass: 'js-active-slide'
              },
              on: {
                click: (e) => {
                  let clicked = e.srcElement;
                  if (clicked.classList.contains('slider__controls-item') === true) {
                    let int = 1500;
                    clearInterval(interval);
                    interval = setInterval(() => {
                      slider.slideNext();
                    }, (10000 + int));
                  }
                }
              }
            });

            // Every 5 seconds
            let interval = setInterval(() => {
              slider.slideNext();
            }, 10000);
          });
        }
      });
    }
}

export default Slider;
