class Nav {

  constructor() {
    this.dom = {
      toggle:  document.querySelectorAll('.js-toggle'),
      menu:    document.querySelector('.js-menu'),
      items:   document.querySelectorAll('.js-menu-link'),
      nav:     document.querySelector('.js-nav'),
      window:  window,
      trigger: 200,
      sticky:  false
    }
  }

  // SlideIn
  slideIn = (items, menu) => {
    TweenMax.to(menu, .75, {
      xPercent: 0,
      ease: Expo.easeInOut
    });
    TweenMax.staggerTo (items, 0.5, {
      x: 0,
      opacity: 1
    }, .10);
  }

  // Initialize
  slideInit = () => {
    let menu = document.querySelector('.js-menu');
    let items = document.querySelectorAll('.js-menu-link');
    // initialize position menu
    TweenMax.set(items, {
      x: 50,
      opacity: 0
    });
    // initialize position menu
    TweenMax.set(menu, {
      xPercent: 100
    });
  }

  // Sliding out of viewport
  slideOut = (menu) => {
    TweenMax.to(menu, .5, {
      xPercent: 100,
      onComplete: this.slideInit,
      ease: Expo.easeInOut
    });
  }

  // Trigger menu
  triggerMenu = (window, menu, trigger = 100) => {

    // Detect scroll
    if (window !== undefined && trigger !== undefined) {
      if (window.scrollY >= trigger) {
        if (this.dom.sticky === false) {
          // Add class
          TweenMax.to(menu, .5, {
            yPercent: 0,
            ease: Expo.easeInOut
          });
          // Add true
          this.dom.sticky = true;
        }
      } else {
        if (this.dom.sticky === true) {
          // Add class
          TweenMax.to(menu, .5, {
            yPercent: -100,
            ease: Expo.easeInOut
          });
          this.dom.sticky = false;
        }
      }
    }
  }

  // init
  init = () => {

    // set constant variables
    const menu = this.dom.menu;
    const items = this.dom.items;

    // Init the hidden nav
    TweenMax.set(this.dom.nav, {
      yPercent: 0
    });

    // On scroll
    // this.dom.window.addEventListener('scroll', () => {
    //   this.triggerMenu(this.dom.window, this.dom.nav, this.dom.trigger);
    // });

    // Run first time to set elements position
    this.slideInit();

    // if exists
    if (this.dom.toggle.length !== undefined) {
      // For each toggle button
      this.dom.toggle.forEach((btn) => {
        // When clicked
        btn.addEventListener('click', () => {
          // If class is added
          if (this.dom.menu.classList.contains('js-opened') === true) {
            this.slideOut(menu);
          } else {
            this.slideIn(items, menu);
          }
          // Toggle class
          this.dom.menu.classList.toggle('js-opened');
        });
      });
    }

    // Log message
    // window.console.log("---");
    // window.console.log("Navigation initialized");
  }
}

export default Nav;
