import "@babel/polyfill";

// styles
import './index.scss';
import TweenMax from 'gsap';

// autograph
window.console.log(
    "%cGewest13",
    "color: #f3f3f4; font-size: 14px; margin: 15px 0; padding: 30px 60px; background-color: #111112; font-family: 'Verdana'; text-transform: uppercase"
)

// imports
import initCookie from './components/cookie/cookie';
import Contact from './components/contact/contact';
// import Accordion from './components/tiles/accordion';
import Nav from './components/nav/nav';
// import Modal from './components/modal/modal';
import Slider from './components/slider/slider';
import Video from './components/video/video';
// import Tiles from './components/tiles/tiles';
// import Iframe from './components/iframe/iframe';

// initialize components
const initContact = new Contact();
initContact.init();

// Cookie
initCookie();

const initNav = new Nav();
initNav.init();

// const initAccordion = new Accordion();
// initAccordion.init();

const initSlider = new Slider();
initSlider.init();

// const initModal = new Modal();
// initModal.init();

// const initTiles = new Tiles();
// initTiles.init();

// const initIframe = new Iframe();
// initIframe.init();

// Enable or disable the overlay
// window.addEventListener('keypress', () => {
//   document.querySelector('.overlay').classList.toggle('active');
// });

// Fire when everything is loaded
import Preload from './components/preload/preload';
const initPreload = new Preload();
initPreload.init();
